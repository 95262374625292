<template>
  <div class="wrap-banner">
    <el-carousel height="474px" :interval="5000">
      <el-carousel-item v-for="item in list" :key="item.id">
        <img
          class="img"
          :src="item.swiperimageurl"
          alt=""
          @click="itemPath(item.jumpurl)"
        />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { getIndexSwiper } from "@/api/index";
export default {
  components: {},
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.getBanner();
  },
  methods: {
    itemPath(path) {
      console.log(11111);
      window.open(path, "_blank");
    },
    getBanner() {
      getIndexSwiper().then((res) => {
        this.list = res.list.data;
        console.log(this.list);
      });
    },
  },
};
</script>
<style scoped lang="scss">
.wrap-banner {
  width: 760px;
  height: 474px;
  margin: 0 10px;
  img {
    height: 474px;
    width: 100%;
  }
}
</style>
