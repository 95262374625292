<template>
  <div class="layout-content wrap-recommend-for-you">
    <div v-for="item in productList" :key="item.tierid">
      <div class="top">
        <div class="title">{{ item.tiername }}</div>
        <div class="intro-card">
          <div
            v-for="child in item.class"
            :key="child.classid"
            class="classid"
            @click="
              (item.currentTab = child.classid), (item.list = child.goodslist)
            "
          >
            <div class="tab">
              <div
                class="item f-c-c"
                :class="{ active: item.currentTab === child.classid }"
              >
                {{ child.classname }}
              </div>
            </div>
          </div>
        </div>
        <div class="more" @click="viewMore(item.tierid, item.currentTab)">
          查看更多 >
        </div>
      </div>

      <div class="list">
        <com-product
          :index="index"
          :item="goods"
          v-for="(goods, index) in item.list"
          :key="index"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getRecommendGoodsData } from "@/api/index";

export default {
  components: {},
  data() {
    return {
      cateList: [],
      currentCate: "",
      productList: [],
      currentTab: 0,
    };
  },
  mounted() {
    this.getProduct();
    // this.getCategory();
  },
  methods: {
    viewMore(tierid, classid) {
      this.$router.push({
        path: "/recommend",
        query: {
          tierid,
          classid,
        },
      });
    },
    selectCate(id) {
      this.currentCate = id;
    },
    getProduct() {
      getRecommendGoodsData().then((res) => {
        this.productList = res.list.map((item) => {
          if (item.class.length) {
            item["currentTab"] = item.class[0].classid;
            item["list"] = item.class[0].goodslist;
          }
          return item;
        });
        console.log(this.productList);
      });
    },
  },
};
</script>
<style scoped lang="scss">
.wrap-recommend-for-you {
  margin-top: 10px;
  .item {
    font-size: 14px;
    font-weight: bold;
    color: #444444;
    line-height: 19px;
    cursor: pointer;
    margin-right: 20px;

    &.active {
      color: var(--color-primary);
      border-bottom: 4px solid var(--color-primary);
    }
  }
  .top {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    margin-bottom: 10px;
  }

  .title {
    min-width: 200px;
    font-weight: bold;
    font-size: 24px;
    color: var(--color-primary);
  }
  .intro-card {
    width: 100%;
    display: flex;
    .classid {
      margin-right: 10px;
    }
  }
  .cate-wrap {
    margin-right: 40px;

    .item {
      width: 134px;
      height: 40px;
      font-size: 18px;
      color: #444444;
      line-height: 24px;
      cursor: pointer;

      &.active {
        color: var(--color-primary);
        text-decoration-line: underline;
      }
    }
  }

  .more {
    min-width: 100px;
    text-align: right;
    font-size: 12px;
    color: var(--color-primary);
    cursor: pointer;
  }

  .list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
}
</style>
