import Vue from "vue";
import VueRouter from "vue-router";
import Router from "vue-router";

import Index from "../views/index/index.vue";

Vue.use(VueRouter);

/**
 * 解决重复点击菜单会控制台报错bug
 */
const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};

const routes = [
  {
    path: "/",
    name: "Index",
    meta: {
      title: "首页",
    },
    component: Index,
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "登录",
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login/index.vue"),
  },
  {
    path: "/register",
    name: "Register",
    meta: {
      title: "注册",
    },
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/register/index.vue"),
  },
  {
    path: "/cart",
    name: "Cart",
    meta: {
      title: "购物车",
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "cart" */ "../views/cart/index.vue"),
  },
  {
    path: "/brand",
    name: "Brand",
    meta: {
      title: "品牌列表",
    },
    component: () =>
      import(/* webpackChunkName: "brand" */ "../views/brand/List.vue"),
  },
  {
    path: "/recommend",
    name: "Recommend",
    meta: {
      title: "推荐列表",
    },
    component: () =>
      import(/* webpackChunkName: "brand" */ "../views/recommend/index.vue"),
  },
  {
    path: "/coupon",
    name: "Coupon",
    meta: {
      title: "会员优惠券",
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "brand" */ "../views/coupon/index.vue"),
  },
  {
    path: "/forgot",
    name: "Forgot",
    meta: {
      title: "忘记密码",
    },
    component: () =>
      import(
        /* webpackChunkName: "forgot" */ "../views/login/ForgotPassword.vue"
      ),
  },
  {
    path: "/news",
    name: "News",
    meta: {
      title: "文章列表",
    },
    component: () =>
      import(/* webpackChunkName: "news" */ "../views/news/List.vue"),
    children: [
      {
        path: "info",
        name: "NewsInfo",
        component: () =>
          import(
            /* webpackChunkName: "newsInfo" */ "../views/news/children/Info.vue"
          ),
        meta: {
          title: "最新资讯",
        },
      },
      {
        path: "infoDetail",
        name: "NewsInfoDetail",
        component: () =>
          import(
            /* webpackChunkName: "newsInfoDetail" */ "../views/news/children/InfoDetail.vue"
          ),
        meta: {
          title: "资讯详情",
        },
      },
    ],
  },
  {
    path: "/goodsCategory",
    name: "GoodsCategory",
    meta: {
      title: "商品分类",
    },
    component: () =>
      import(
        /* webpackChunkName: "goodsCategory" */ "../views/goods/Category.vue"
      ),
  },
  {
    path: "/goodsList",
    name: "GoodsList",
    meta: {
      title: "商品列表",
    },
    component: () =>
      import(/* webpackChunkName: "goodsList" */ "../views/goods/List.vue"),
  },
  {
    path: "/goodsDetail",
    name: "GoodsDetail",
    meta: {
      title: "商品详情",
    },
    component: () =>
      import(/* webpackChunkName: "goodsDetail" */ "../views/goods/Detail.vue"),
  },
  {
    path: "/quote",
    name: "Quote",
    meta: {
      title: "快速报价",
    },
    component: () =>
      import(/* webpackChunkName: "quote" */ "../views/quote/index.vue"),
  },
  {
    path: "/settlement",
    name: "Settlement",
    meta: {
      title: "结算单",
    },
    component: () =>
      import(
        /* webpackChunkName: "settlement" */ "../views/settlement/index.vue"
      ),
  },
  {
    path: "/pay",
    name: "Pay",
    meta: {
      title: "确认支付",
    },
    component: () =>
      import(/* webpackChunkName: "pay" */ "../views/pay/index.vue"),
  },
  {
    path: "/payResult",
    name: "PayResult",
    meta: {
      title: "支付结果",
    },
    component: () =>
      import(/* webpackChunkName: "payResult" */ "../views/pay/result.vue"),
  },
  {
    path: "/aboutUs",
    name: "AboutUs",
    meta: {
      title: "关于我们",
    },
    component: () =>
      import(/* webpackChunkName: "aboutUs" */ "../views/about/index.vue"),
  },
  {
    path: "/user",
    name: "User",
    component: () =>
      import(/* webpackChunkName: "user" */ "../views/user/index.vue"),
    children: [
      {
        path: "info",
        name: "Info",
        component: () =>
          import(
            /* webpackChunkName: "info" */ "../views/user/children/Info.vue"
          ),
        meta: {
          title: "个人资料",
          requiresAuth: true,
        },
      },
      {
        path: "myPurse",
        name: "MyPurse",
        component: () =>
          import(
            /* webpackChunkName: "myPurse" */ "../views/user/children/MyPurse.vue"
          ),
        meta: {
          title: "我的钱包",
          requiresAuth: true,
        },
      },
      {
        path: "MyCoupon",
        name: "MyCoupon",
        component: () =>
          import(
            /* webpackChunkName: "myPurse" */ "../views/user/children/MyCoupon.vue"
          ),
        meta: {
          title: "我的优惠券",
          requiresAuth: true,
        },
      },
      {
        path: "myQuote",
        name: "MyQuote",
        component: () =>
          import(
            /* webpackChunkName: "myQuote" */ "../views/user/children/MyQuote.vue"
          ),
        meta: {
          title: "我的报价单",
          requiresAuth: true,
        },
      },
      {
        path: "MyList",
        name: "MyList",
        component: () =>
          import(
            /* webpackChunkName: "MyList" */ "../views/user/children/MyList.vue"
          ),
        meta: {
          title: "我的采购清单",
          requiresAuth: true,
        },
      },
      {
        path: "myOrder",
        name: "MyOrder",
        component: () =>
          import(
            /* webpackChunkName: "myOrder" */ "../views/user/children/MyOrder.vue"
          ),
        meta: {
          title: "我的订单",
          requiresAuth: true,
        },
      },
      {
        path: "myAfterSale",
        name: "MyAfterSale",
        component: () =>
          import(
            /* webpackChunkName: "MyAfterSale" */ "../views/user/children/MyAfterSale.vue"
          ),
        meta: {
          title: "我的售后",
          requiresAuth: true,
        },
      },
      {
        path: "myAfterRefund",
        name: "MyAfterRefund",
        component: () =>
          import(
            /* webpackChunkName: "MyAfterSale" */ "../views/user/children/MyAfterRefund.vue"
          ),
        meta: {
          title: "售后退款",
          requiresAuth: true,
        },
      },
      {
        path: "myAfterGoods",
        name: "MyAfterGoods",
        component: () =>
          import(
            /* webpackChunkName: "MyAfterSale" */ "../views/user/children/MyAfterGoods.vue"
          ),
        meta: {
          title: "退货退款",
          requiresAuth: true,
        },
      },
      {
        path: "myInvoice",
        name: "MyInvoice",
        component: () =>
          import(
            /* webpackChunkName: "myInvoice" */ "../views/user/children/MyInvoice.vue"
          ),
        meta: {
          title: "发票管理",
          requiresAuth: true,
        },
      },
      {
        path: "myCollect",
        name: "MyCollect",
        component: () =>
          import(
            /* webpackChunkName: "myCollect" */ "../views/user/children/MyCollect.vue"
          ),
        meta: {
          title: "我的收藏",
          requiresAuth: true,
        },
      },
      {
        path: "myAddress",
        name: "MyAddress",
        component: () =>
          import(
            /* webpackChunkName: "myAddress" */ "../views/user/children/MyAddress.vue"
          ),
        meta: {
          title: "收货地址",
          requiresAuth: true,
        },
      },
      {
        path: "GenerationMyAddress",
        name: "GenerationMyAddress",
        component: () =>
          import(
            /* webpackChunkName: "myAddress" */ "../views/user/children/GenerationMyAddress.vue"
          ),
        meta: {
          title: "代收货地址",
          requiresAuth: true,
        },
      },
      {
        path: "myPassword",
        name: "MyPassword",
        component: () =>
          import(
            /* webpackChunkName: "myPassword" */ "../views/user/children/MyPassword.vue"
          ),
        meta: {
          title: "密码修改",
          requiresAuth: true,
        },
      },
      {
        path: "purseDetail",
        name: "PurseDetail",
        component: () =>
          import(
            /* webpackChunkName: "purseDetail" */ "../views/user/children/PurseDetail.vue"
          ),
        meta: {
          title: "余额明细",
          requiresAuth: true,
        },
      },
      {
        path: "purseAdd",
        name: "PurseAdd",
        component: () =>
          import(
            /* webpackChunkName: "purseAdd" */ "../views/user/children/PurseAdd.vue"
          ),
        meta: {
          title: "余额充值",
          requiresAuth: true,
        },
      },
      {
        path: "myQuoteDetail",
        name: "MyQuoteDetail",
        component: () =>
          import(
            /* webpackChunkName: "myQuoteDetail" */ "../views/user/children/MyQuoteDetail.vue"
          ),
        meta: {
          title: "报价单明细",
          requiresAuth: true,
        },
      },
      {
        path: "myOrderDetail",
        name: "MyOrderDetail",
        component: () =>
          import(
            /* webpackChunkName: "myOrderDetail" */ "../views/user/children/MyOrderDetail.vue"
          ),
        meta: {
          title: "订单详情",
          requiresAuth: true,
        },
      },
      {
        path: "applyAfterSale",
        name: "ApplyAfterSale",
        component: () =>
          import(
            /* webpackChunkName: "applyAfterSale" */ "../views/user/children/ApplyAfterSale.vue"
          ),
        meta: {
          title: "申请售后",
          requiresAuth: true,
        },
      },
    ],
  },
];
import { getToken } from "@/utils/cookies";

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const token = getToken();

  if (requiresAuth && !token) {
    next("/login"); // 如果需要认证但未登录，重定向到登录页面
  } else if (!requiresAuth && token) {
    next(); // 如果不需要认证但已登录，允许访问
  } else {
    next(); // 其他情况正常访问
  }
});
export default router;
